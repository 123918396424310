/**  */

// eslint-disable-next-line
import React from 'react';
import { Link } from 'gatsby';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

import { bp } from '../../../config/breakpoints';

// Components
import MUIButtonTag from '../../Common/Button/MUIButtonTag';

const cssItem = css`
  button {
    /* @media (max-width: ${bp.mx_md}) {
      width: 50%;
    } */
  }
`;

const TagItem = ({ slug, name, baseSlug, taxSlug }) => (
  <li css={cssItem} key={shortid.generate()}>
    <Link to={`/${baseSlug}/${taxSlug}/${slug}/`}>
      <MUIButtonTag>{name}</MUIButtonTag>
    </Link>
  </li>
);

TagItem.propTypes = {
  baseSlug: PropTypes.string.isRequired,
  taxSlug: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TagItem;
