import React from 'react';
import { Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { darken } from 'polished';
import PropTypes from 'prop-types';

// MUI Overrides
const styles = {
  root: {
    borderRadius: 0,
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    backgroundColor: '#009FE3',
    color: '#FFF',
    '&:hover': {
      backgroundColor: darken(0.05, '#1E778B'),
    },
  },
};

const MUIButtonTag = (props) => {
  const { classes } = props;

  return (
    <Button className={classes.root} variant="contained" {...props}>
      {props.children}
    </Button>
  );
};

MUIButtonTag.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(MUIButtonTag);
